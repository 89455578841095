<template>
  <div v-if="isAllNew()">
    <MarginCalculatedNew />
  </div>
  <div v-else>
    <MarginCalculatedOld />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MarginCalculatedNew from '../components/MarginCalculatedNew.vue'
import MarginCalculatedOld from '../components/MarginCalculatedOld.vue'

export default {
  components: {
    MarginCalculatedNew,
    MarginCalculatedOld
  },
  computed: {
    ...mapGetters({
      orders: 'Prototypes/orders'
    })
  },
  methods: {
    isAllNew () {
      return this.orders.every((o) => 'colors' in o.order)
    }
  }
}
</script>
