<template>
  <v-card class="pa-4 card">
    <img-popup-preview
      v-model="previewEnabled"
      :img-src="currentImgPreview" />
    <div class="d-flex summary-tab">
      <v-card
        class="number-box"
        elevation="2">
        <h3 class="title">
          Avg. Margin
        </h3>
        <h2 class="content">
          {{ averageMargin | showFullPriceFormat() }}
        </h2>
      </v-card>
      <v-card
        class="number-box"
        elevation="2">
        <h3 class="title">
          Stock Cost
        </h3>
        <h2 class="content">
          {{ stockCost | showFullPriceFormat() }}
        </h2>
      </v-card>
      <v-card
        class="number-box"
        elevation="2">
        <h3 class="title">
          Stock Value
        </h3>
        <h2 class="content">
          {{ stockValue | showFullPriceFormat() }}
        </h2>
      </v-card>
      <v-btn
        color="primary"
        height="80"
        width="80"
        class="mx-2"
        @click="exportCsv()">
        Export<br />CSV
      </v-btn>
      <v-btn
        color="success"
        height="80"
        width="80"
        @click="saveOrders()">
        อัพเดต<br />ใบงาน
      </v-btn>
    </div>
    <v-data-table
      :items-per-page="-1"
      hide-default-footer
      :headers="headers"
      :items="calculatingOrders"
      class="table">
      <template #[`item.image`]="{ item }">
        <img
          :src="item.image"
          class="detail-box-image"
          @click="previewImg(item.image)" />
      </template>
      <template #[`item.manufacAmount`]="{ item }">
        <span class="text-center">
          {{ getManufacAmount(item.order.productSkus) }}
        </span>
      </template>
      <template #[`item.order.manufacCountry`]="{ item }">
        <v-select
          v-model="item.order.manufacCountry"
          hide-details
          dense
          :value="!!item.order.manufacCountry ? item.order.manufacCountry : 'thailand'"
          :items="manufacCountries"
          @input="reCalc()" />
      </template>
      <template #[`item.order.clothesCostPerMetr`]="{ item }">
        <v-text-field
          v-model.number="item.order.clothesCostPerMetr"
          hide-details
          dense
          @input="reCalc()" />
      </template>
      <template #[`item.order.sellPrice`]="{ item }">
        <v-text-field
          v-model.number="item.order.sellPrice"
          hide-details
          dense
          @input="reCalc()" />
      </template>
      <template #[`item.order.clothesLength`]="{ item }">
        <v-text-field
          v-model.number="item.order.clothesLength"
          hide-details
          dense
          @input="reCalc()" />
      </template>
      <template #[`item.order.costInCny`]="{ item }">
        <v-text-field
          v-model.number="item.order.costInCny"
          :disabled="disableByCountry(item.order.manufacCountry, 'china')"
          hide-details
          dense
          @input="reCalc()" />
      </template>
      <template #[`item.order.costInThb`]="{ item }">
        <v-text-field
          v-model.number="item.order.costInThb"
          :disabled="disableByCountry(item.order.manufacCountry, 'thailand')"
          hide-details
          dense
          @input="reCalc()" />
      </template>
      <template #[`item.order.materialsCost`]="{ item }">
        {{ item.order.materialsCost | showFullPriceFormat() }}
      </template>
      <template #[`item.order.cost`]="{ item }">
        {{ item.order.cost | showFullPriceFormat() }}
      </template>
      <template #[`item.order.margin`]="{ item }">
        {{ item.order.margin | showFullPriceFormat() }}
      </template>
      <template #[`item.order.priceWithoutVat`]="{ item }">
        {{ item.order.priceWithoutVat | showFullPriceFormat() }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PrototypeOrderProviderOld from '@/resources/PrototypeOrderProviderOld'
import { ExportToCsv } from 'export-to-csv'
import ImgPopupPreview from './ImgModalPreview.vue'

const PrototypeOrderService = new PrototypeOrderProviderOld()

export default {
  components: {
    ImgPopupPreview
  },
  data () {
    return {
      previewEnabled: false,
      currentImgPreview: null,
      manufacCountries: [
        { text: 'ไทย', value: 'thailand' },
        { text: 'จีน', value: 'china' }
      ],
      CNY_MULTIPLE: 5
    }
  },
  computed: {
    ...mapGetters({
      orders: 'Prototypes/orders'
    }),
    headers () {
      return [
        { text: '', value: 'image', width: '10%', align: 'center' },
        { text: 'รุ่น', value: 'sketch.sketchId', width: '5%', align: 'center' },
        { text: 'จำนวนผลิต', value: 'manufacAmount', width: '10%', align: 'center' },
        { text: 'ที่ตั้งโรงงาน', value: 'order.manufacCountry', width: '5%', align: 'center' },
        { text: 'ราคาผ้า (หยวน)', value: 'order.clothesCostPerMetr', width: '10%', align: 'left' },
        { text: 'ราคาขาย (บาท)', value: 'order.sellPrice', width: '10%', align: 'left' },
        { text: 'จำนวนผ้า (เมตร)', value: 'order.clothesLength', width: '10%', align: 'left' },
        { text: 'ค่าผลิต (หยวน)', value: 'order.costInCny', width: '10%', align: 'left' },
        { text: 'ค่าผลิต (บาท)', value: 'order.costInThb', width: '10%', align: 'left' },
        { text: 'ค่าอะไหล่ (บาท)', value: 'order.materialsCost', width: '10%', align: 'left' },
        { text: 'ค่าผลิตรวม (บาท)', value: 'order.cost', width: '10%', align: 'left' },
        { text: 'Margin', value: 'order.margin', width: '10%', align: 'center' },
        { text: 'ราคาขาย (ถอด Vat)', value: 'order.priceWithoutVat', width: '10%', align: 'left' }
      ]
    },
    calculatingOrders: {
      get () {
        return this.orders
      },
      set (value) {
        this.setOrders(value)
      }
    },
    stockCost () {
      return this.calculatingOrders.reduce((total, acc) => total + acc.order?.stockCost || 0, 0)
    },
    stockValue () {
      return this.calculatingOrders.reduce((total, acc) => total + acc.order?.stockValue || 0, 0)
    },
    averageMargin () {
      const sumMargin = this.calculatingOrders.reduce((total, acc) => {
        const margin = acc.order?.margin || 0
        const totalMargin = margin * this.getManufacAmount(acc.order.productSkus)
        return total + totalMargin
      }, 0)
      const amountOfManufacAmount = this.calculatingOrders.reduce((total, acc) => total + this.getManufacAmount(acc.order.productSkus), 0)
      const avg = sumMargin / amountOfManufacAmount

      return (amountOfManufacAmount > 0) ? Math.round((avg + Number.EPSILON) * 100) / 100 : 0
    }
  },
  created () {
    this.calculatingOrders = this.initIndex(this.calculatingOrders)
    this.calculatingOrders = this.initMaterialsCost(this.calculatingOrders)
    this.calculatingOrders = this.initManufacCost(this.calculatingOrders)
    this.calculatingOrders = this.initStockCostAndStockValueAndMargin(this.calculatingOrders)
    this.calculatingOrders = this.initImage(this.calculatingOrders)
  },
  methods: {
    ...mapActions({
      setOrders: 'Prototypes/setOrders',
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    initImage (orders) {
      return orders.map((_order) => {
        const order = _order
        const image = order.order?.fitting?.images?.length > 0 ? order.order?.fitting?.images[0] : order.sketch?.images[0]
        order.image = image

        return order
      })
    },
    initManufacCountry (orders) {
      return orders.map((_order) => {
        const order = _order
        order.order.manufacCountry = order.order.manufacCountry || 'thailand'

        return order
      })
    },
    initIndex (orders) {
      return orders.map((order, index) => ({
        ...order,
        index
      }))
    },
    initMaterialsCost (orders) {
      return orders.map((prototype) => {
        const materialsCost = prototype.order.materials.reduce((total, acc) => {
          const amount = acc.quantity || 0
          const unitPrice = acc.unitPrice || 0

          return total + (amount * unitPrice * this.CNY_MULTIPLE)
        }, 0)

        const order = {
          ...prototype.order,
          materialsCost
        }

        return {
          ...prototype,
          order
        }
      })
    },
    initManufacCost (prototypes) {
      return prototypes.map((prototype) => {
        const tmpPrototype = prototype

        if (tmpPrototype.order.manufacCountry === 'thailand') {
          tmpPrototype.order['costInThb'] = tmpPrototype.order.costInThb
          || tmpPrototype.order.manufacCost
        }

        if (tmpPrototype.order.manufacCountry === 'china') {
          tmpPrototype.order['costInCny'] = tmpPrototype.order.costInCny
          || tmpPrototype.order.manufacCost
        }

        if (tmpPrototype.order.manufacCountry !== 'thailand') {
          tmpPrototype.order['costInThb'] = null
        }

        if (tmpPrototype.order.manufacCountry !== 'china') {
          tmpPrototype.order['costInCny'] = null
        }

        return tmpPrototype
      })
    },
    initStockCostAndStockValueAndMargin (orders) {
      return orders.map((_order) => {
        const order = _order

        const newItem = this.calculatePriceAndMargin(order)
        const { stockCost, stockValue } = this.caculateStockCostAndStockValue(newItem.order)
        newItem.order = {
          ...newItem.order,
          stockCost,
          stockValue
        }

        return newItem
      })
    },
    disableByCountry (country, target) {
      return country !== target
    },
    avoidNullValue (object, properties, defaultValue = '-') {
      return object[properties] || defaultValue
    },
    getManufacAmount (skus) {
      return skus.reduce((total, acc) => total + acc.quantity, 0)
    },
    previewImg (img) {
      this.currentImgPreview = img
      this.previewEnabled = !!this.currentImgPreview
    },
    reCalc () {
      this.calculatingOrders = this.calculatingOrders.map((item) => {
        const newItem = this.calculatePriceAndMargin(item)
        const { stockCost, stockValue } = this.caculateStockCostAndStockValue(newItem.order)

        return {
          ...newItem,
          order: {
            ...newItem.order,
            stockCost,
            stockValue
          }
        }
      })
    },
    caculateStockCostAndStockValue (order) {
      const manufacAmount = this.getManufacAmount(order?.productSkus) || 0
      const cost = order?.cost || 0
      const sellPrice = order?.sellPrice || 0

      return {
        stockCost: cost * manufacAmount || 0,
        stockValue: sellPrice * manufacAmount || 0
      }
    },
    calculatePriceAndMargin (item) {
      const clothesLength = item.order?.clothesLength || 0
      const clothesCostPerMetr = item.order.clothesCostPerMetr || 0
      // const materialsCost = item.order.materialsCost || 0
      const materialsCost = item.order.materials.reduce((total, acc) => total + (acc.unitPrice * acc.quantity * this.CNY_MULTIPLE), 0)

      const manufacCost = item.order.manufacCountry === 'thailand'
        ? item.order?.costInThb || 0
        : item.order?.costInCny * this.CNY_MULTIPLE || 0

      const cost = (clothesLength * clothesCostPerMetr * this.CNY_MULTIPLE) + manufacCost + materialsCost || 0
      const priceWithoutVat = Number(((item.order?.sellPrice || 0) / 1.07).toFixed(2))
      const margin = Number((priceWithoutVat / cost).toFixed(2))

      return {
        ...item,
        order: {
          ...item.order,
          margin,
          priceWithoutVat,
          cost
        }
      }
    },
    async saveOrders () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'UPDATE ORDERS...'
        })

        const ids = this.calculatingOrders.map((each) => each.id)
        const orders = this.calculatingOrders.map((each) => this.mapManufacCostForUpdate(each.order))

        await PrototypeOrderService.editManyOrders({ ids, orders })

        this.setSnackbar({
          value: true,
          message: 'Update order success',
          type: 'success'
        })
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    mapManufacCostForUpdate (_order) {
      return {
        ..._order,
        manufacCost: _order.manufacCountry === 'thailand' ? _order.costInThb : _order.costInCny
      }
    },
    exportCsv () {
      const mapped = this.calculatingOrders.map((prototype) => ({
        'id': prototype.sketch.sketchId || '',
        'จำนวนผลิต': this.getManufacAmount(prototype.order.productSkus),
        'ที่ตั้งโรงงาน': prototype.order.manufacCountry,
        'ราคาผ้า(หยวน)': prototype.order.clothesCostPerMetr,
        'ราคาขาย(บาท)': prototype.order.sellPrice,
        'จำนวนผ้า(เมตร)': prototype.order.clothesLength,
        'ค่าผลิต(หยวน)': prototype.order.manufacCountry === 'china' ? prototype.order.costInCny : '',
        'ค่าผลิต(บาท)': prototype.order.manufacCountry === 'thailand' ? prototype.order.costInThb : '',
        'ค่าผลิตรวม(บาท)': prototype.order.cost || 0,
        'Margin': prototype.order.margin?.toFixed(2) || '',
        'ราคาไม่รวม VAT': prototype.order.priceWithoutVat?.toFixed(2) || 0
      }))

      const _blank = {
        'id': '',
        'จำนวนผลิต': '',
        'ที่ตั้งโรงงาน': '',
        'ราคาผ้า(หยวน)': '',
        'ราคาขาย(บาท)': '',
        'จำนวนผ้า(เมตร)': '',
        'ค่าผลิต(หยวน)': '',
        'ค่าผลิต(บาท)': '',
        'ค่าผลิตรวม(บาท)': '',
        'Margin': '',
        'ราคาไม่รวม VAT': ''
      }

      mapped.push(_blank)
      mapped.push(_blank)

      mapped.push({
        'id': '',
        'จำนวนผลิต': 'Stock Cost:',
        'ที่ตั้งโรงงาน': this.stockCost,
        'ราคาผ้า(หยวน)': '',
        'ราคาขาย(บาท)': 'Stock Value:',
        'จำนวนผ้า(เมตร)': this.stockValue,
        'ค่าผลิต(หยวน)': '',
        'ค่าผลิต(บาท)': 'Avg. Margin:',
        'ค่าผลิตรวม(บาท)': this.averageMargin,
        'Margin': '',
        'ราคาไม่รวม VAT': ''
      })

      const options = {
        filename: `MASTER-FILE(${this.$dayjs().format('YYYY-MM-DDTHH-mm')})`,
        showLabels: true,
        useKeysAsHeaders: true
      }

      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(mapped)
    }
  }
}
</script>

<style scoped>
.card {
  position: relative;
  min-height: 90vh;
}

.summary-tab {
  position: fixed;
  width: 77%;
  z-index: 998;
}

img.detail-box-image {
  cursor: pointer;
  max-height: 80px;
  transition: transform 0.1s;
}

.number-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 70%;
  border: 2px solid black;
  position: relative;
  margin: 0 4px;
  background-color: #fff;
}

.number-box .title {
  position: absolute;
  top: -17px;
  left: 5px;
  padding: 0 5px;
  background-color: #fff;
}

.number-box .extend {
  position: absolute;
  bottom: 0px;
  right: 2px;
  background-color: #fff;
}

.table {
  margin-top: 84px;
}
</style>
