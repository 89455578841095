<template>
  <v-card class="pa-4 card">
    <img-popup-preview
      v-model="previewEnabled"
      :img-src="currentImgPreview" />
    <div class="d-flex summary-tab">
      <v-card
        class="number-box"
        elevation="2">
        <h3 class="title">
          Avg. Margin
        </h3>
        <h2 class="content">
          {{ averageMargin | showFullPriceFormat() }}
        </h2>
      </v-card>
      <v-card
        class="number-box"
        elevation="2">
        <h3 class="title">
          Stock Cost
        </h3>
        <h2 class="content">
          {{ stockCost | showFullPriceFormat() }}
        </h2>
      </v-card>
      <v-card
        class="number-box"
        elevation="2">
        <h3 class="title">
          Stock Value
        </h3>
        <h2 class="content">
          {{ stockValue | showFullPriceFormat() }}
        </h2>
      </v-card>
      <v-btn
        color="primary"
        height="80"
        width="80"
        class="mx-2"
        @click="exportCsv()">
        Export<br />CSV
      </v-btn>
      <v-btn
        color="success"
        height="80"
        width="80"
        @click="saveOrders()">
        อัพเดต<br />ใบงาน
      </v-btn>
    </div>
    <v-data-table
      :items-per-page="-1"
      hide-default-footer
      :headers="headers"
      :items="formPrototypes"
      class="table">
      <template #[`item.image`]="{ item }">
        <img
          :src="item.image"
          class="detail-box-image"
          @click="previewImg(item.image)" />
      </template>
      <template #[`item.manufacAmount`]="{ item }">
        <div class="d-flex justify-center align-center my-4 text-center">
          <v-row>
            <v-col
              v-for="(c, i) in item.order.colors"
              :key="`${c.color}-${i}`"
              cols="6">
              <v-text-field
                v-model.number="item.order.colors[i].totalQty"
                :label="c.color.toUpperCase()"
                hide-details
                dense
                color="secondary"
                @input="reCalc()" />
            </v-col>
          </v-row>
        </div>
      </template>
      <template #[`item.order.sellPrice`]="{ item }">
        <v-text-field
          v-model.number="item.order.sellPrice"
          hide-details
          dense
          color="secondary"
          @input="reCalc()" />
      </template>
      <template #[`item.order.manufacCost`]="{ item }">
        <v-text-field
          v-model.number="item.order.manufacCost"
          hide-details
          dense
          :suffix="item.order.manufacCountry === 'china' ? '¥' : '฿'"
          color="secondary"
          @input="reCalc()" />
      </template>
      <template #[`item.order.manufacCountry`]="{ item }">
        <span class="text-center">
          {{ item.order.manufacCountry === 'china' ? 'จีน' : 'ไทย' }}
        </span>
      </template>
      <template #[`item.order.fabricsCost`]="{ item }">
        {{ item.order.fabricsCost | showFullPriceFormat() }}
      </template>
      <template #[`item.order.materialsCost`]="{ item }">
        {{ item.order.materialsCost | showFullPriceFormat() }}
      </template>
      <template #[`item.order.cost`]="{ item }">
        {{ item.order.cost | showFullPriceFormat() }}
      </template>
      <template #[`item.order.margin`]="{ item }">
        {{ item.order.margin | showFullPriceFormat() }}
      </template>
      <template #[`item.order.priceWithoutVat`]="{ item }">
        {{ item.order.priceWithoutVat | showFullPriceFormat() }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PrototypeOrderProvider from '@/resources/PrototypeOrderProvider'
import { ExportToCsv } from 'export-to-csv'
import ImgPopupPreview from './ImgModalPreview.vue'

const PrototypeOrderService = new PrototypeOrderProvider()

export default {
  components: {
    ImgPopupPreview
  },
  data () {
    return {
      previewEnabled: false,
      currentImgPreview: null,
      manufacCountries: [
        { text: 'ไทย', value: 'thailand' },
        { text: 'จีน', value: 'china' }
      ],
      CNY_MULTIPLE: 5
    }
  },
  computed: {
    ...mapGetters({
      orders: 'Prototypes/orders'
    }),
    headers () {
      return [
        {
          text: '',
          value: 'image',
          width: '10%',
          align: 'center',
          sortable: false
        },
        {
          text: 'รุ่น',
          value: 'sketch.sketchId',
          align: 'center',
          sortable: false
        },
        {
          text: 'จำนวนผลิต',
          value: 'manufacAmount',
          width: '200px',
          align: 'center',
          sortable: false
        },
        {
          text: 'ราคาขาย (บาท)',
          value: 'order.sellPrice',
          width: '100px',
          align: 'left',
          sortable: false
        },
        {
          text: 'ค่าผลิต',
          value: 'order.manufacCost',
          width: '100px',
          align: 'left',
          sortable: false
        },
        {
          text: 'ที่ตั้งโรงงาน',
          value: 'order.manufacCountry',
          align: 'center',
          sortable: false
        },
        {
          text: 'ค่าผ้า (บาท)',
          value: 'order.fabricsCost',
          align: 'left',
          sortable: false
        },
        {
          text: 'ค่าอะไหล่ (บาท)',
          value: 'order.materialsCost',
          align: 'left',
          sortable: false
        },
        {
          text: 'ต้นทุนรวม (บาท)',
          value: 'order.cost',
          align: 'left',
          sortable: false
        },
        {
          text: 'Margin',
          value: 'order.margin',
          align: 'center',
          sortable: false
        },
        {
          text: 'ราคาขาย (ถอด Vat)',
          value: 'order.priceWithoutVat',
          align: 'left',
          sortable: false
        }
      ]
    },
    formPrototypes: {
      get () {
        return this.orders
      },
      set (value) {
        this.setOrders(value)
      }
    },
    stockCost () {
      return this.formPrototypes.reduce((total, acc) => total + acc.order?.stockCost || 0, 0)
    },
    stockValue () {
      return this.formPrototypes.reduce((total, acc) => total + acc.order?.stockValue || 0, 0)
    },
    averageMargin () {
      const sumMargin = this.formPrototypes.reduce((total, acc) => {
        const margin = acc.order?.margin || 0
        const totalMargin = margin * this.getManufacAmount(acc.order.colors)
        return total + totalMargin
      }, 0)
      const amountOfManufacAmount = this.formPrototypes.reduce((total, acc) => total + this.getManufacAmount(acc.order.colors), 0)
      const avg = sumMargin / amountOfManufacAmount

      return (amountOfManufacAmount > 0) ? Math.round((avg + Number.EPSILON) * 100) / 100 : 0
    }
  },
  created () {
    this.initOrder()
  },
  methods: {
    ...mapActions({
      setOrders: 'Prototypes/setOrders',
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    initOrder () {
      this.formPrototypes = this.formPrototypes.map((prototype, i) => {
        const materialsCost = prototype.order.colors.map((o) => {
          const cost = o.materials.map((m) => {
            if (m.currency === 'cny') {
              return m.unitPrice * m.amount * this.CNY_MULTIPLE
            }
            return m.unitPrice * m.amount
          })

          return cost.reduce((total, acc) => total + acc, 0)
        }).reduce((total, acc) => total + acc, 0)

        const fabricsCost = prototype.order.colors.map((o) => {
          const cost = o.fabrics.map((f) => {
            if (f.currency === 'cny') {
              return f.unitPrice * f.amount * this.CNY_MULTIPLE
            }
            return f.unitPrice * f.amount
          })

          return cost.reduce((total, acc) => total + acc, 0)
        }).reduce((total, acc) => total + acc, 0)

        const newPrototype = this.calculatePriceAndMargin(prototype)
        const { stockCost, stockValue } = this.caculateStockCostAndStockValue(newPrototype.order)
        const fittings = Array.isArray(newPrototype.order.fitting) ? [...newPrototype.order.fitting] : [newPrototype.order.fitting]
        // const lastFitting = fittings[fittings.length - 1]
        // const lastImage = lastFitting.images[lastFitting.images.length - 1]

        // const image = lastImage || newPrototype.sketch?.images[0]
        const image = fittings[0].images[0] || newPrototype.sketch?.images[0]

        return {
          ...newPrototype,
          order: {
            ...newPrototype.order,
            materialsCost,
            fabricsCost,
            stockCost,
            stockValue
          },
          image,
          index: i
        }
      })
    },
    disableByCountry (country, target) {
      return country !== target
    },
    avoidNullValue (object, properties, defaultValue = '-') {
      return object[properties] || defaultValue
    },
    getManufacAmount (colors) {
      return colors.reduce((total, acc) => total + acc.totalQty, 0)
    },
    previewImg (img) {
      this.currentImgPreview = img
      this.previewEnabled = !!this.currentImgPreview
    },
    caculateStockCostAndStockValue (order) {
      const manufacAmount = this.getManufacAmount(order?.colors) || 0

      return {
        stockCost: order.cost * manufacAmount || 0,
        stockValue: order.sellPrice * manufacAmount || 0
      }
    },
    calculatePriceAndMargin (item) {
      const materialsCost = item.order.colors.map((o) => {
        const cost = o.materials.map((m) => {
          if (m.currency === 'cny') {
            return m.unitPrice * m.amount * this.CNY_MULTIPLE
          }
          return m.unitPrice * m.amount
        })

        return cost.reduce((total, acc) => total + acc, 0)
      }).reduce((total, acc) => total + acc, 0)

      const fabricsCost = item.order.colors.map((o) => {
        const cost = o.fabrics.map((f) => {
          if (f.currency === 'cny') {
            return f.unitPrice * f.amount * this.CNY_MULTIPLE
          }
          return f.unitPrice * f.amount
        })

        return cost.reduce((total, acc) => total + acc, 0)
      }).reduce((total, acc) => total + acc, 0)

      const manufacCost = item.order.manufacCountry === 'china' ? (item.order.manufacCost * this.CNY_MULTIPLE) : item.order.manufacCost
      const cost = materialsCost + fabricsCost + manufacCost
      const priceWithoutVat = Number(((item.order?.sellPrice || 0) / 1.07).toFixed(2))
      const margin = Number((priceWithoutVat / cost).toFixed(2))

      return {
        ...item,
        order: {
          ...item.order,
          margin,
          priceWithoutVat,
          cost
        }
      }
    },
    async saveOrders () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'UPDATE ORDERS...'
        })

        const ids = this.formPrototypes.map((each) => each.id)
        const orders = this.formPrototypes.map((each) => {
          const order = { ...each.order }
          const indexedSkus = order.productSkus.map((sku, index) => ({ ...sku, index }))
          const colors = order.colors.map((c) => {
            const skus = indexedSkus.filter((sku) => sku.color === c.color)

            return {
              ...c,
              skus
            }
          })

          for (const c of colors) {
            let total = c.totalQty

            const sortSkus = [...c.skus].sort((a, b) => b.percent - a.percent)

            for (const sku of sortSkus) {
              const qty = Math.floor(c.totalQty * (sku.percent / 100))

              if (total >= qty) {
                order.productSkus[sku.index].quantity = qty
                total -= qty
              } else {
                order.productSkus[sku.index].quantity = total
                total = 0
              }
            }

            while (total > 0) {
              for (const sku of sortSkus) {
                if (total > 0) {
                  order.productSkus[sku.index].quantity += 1
                  total -= 1
                }
              }
            }
          }

          order.productSkus = order.productSkus.map((sku) => ({
            ...sku,
            ratio: order.margin,
            cost: order.cost
          }))

          return order
        })

        await PrototypeOrderService.editManyOrders({ ids, orders })

        this.setSnackbar({
          value: true,
          message: 'Update order success',
          type: 'success'
        })
      } catch (error) {
        console.error('saveOrders', error)
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    exportCsv () {
      const mapped = this.formPrototypes.map((prototype) => ({
        'id': prototype.sketch.sketchId || '',
        'จำนวนผลิต': this.getManufacAmount(prototype.order.colors),
        'ที่ตั้งโรงงาน': prototype.order.manufacCountry,
        'ราคาขาย (บาท)': prototype.order.sellPrice,
        'ค่าผลิต (บาท)': (prototype.order.manufacCountry === 'china')
          ? '-'
          : prototype.order.manufacCost.toFixed(2),
        'ค่าผลิต (หยวน)': (prototype.order.manufacCountry === 'china')
          ? prototype.order.manufacCost.toFixed(2)
          : '-',
        'ค่าผ้า (บาท)': prototype.order.fabricsCost || 0,
        'ค่าอะไหล่ (บาท)': prototype.order.materialsCost || 0,
        'ต้นทุนรวม (บาท)': prototype.order.cost || 0,
        'Margin': prototype.order.margin?.toFixed(2) || '',
        'ราคาขาย (ถอด Vat)': prototype.order.priceWithoutVat?.toFixed(2) || 0
      }))

      const _blank = {
        'id': '',
        'จำนวนผลิต': '',
        'ที่ตั้งโรงงาน': '',
        'ราคาขาย (บาท)': '',
        'ค่าผลิต (บาท)': '',
        'ค่าผลิต (หยวน)': '',
        'ค่าผ้า (บาท)': '',
        'ค่าอะไหล่ (บาท)': '',
        'ต้นทุนรวม (บาท)': '',
        'Margin': '',
        'ราคาขาย (ถอด Vat)': ''
      }

      mapped.push(_blank)
      mapped.push(_blank)

      mapped.push({
        'id': '',
        'จำนวนผลิต': 'Stock Cost:',
        'ที่ตั้งโรงงาน': this.stockCost,
        'ราคาขาย (บาท)': '',
        'ค่าผลิต (บาท)': '',
        'ค่าผลิต (หยวน)': '',
        'ค่าผ้า (บาท)': 'Stock Value:',
        'ค่าอะไหล่ (บาท)': this.stockValue,
        'ต้นทุนรวม (บาท)': '',
        'Margin': 'Avg. Margin:',
        'ราคาขาย (ถอด Vat)': this.averageMargin
      })

      const options = {
        filename: `MASTER-FILE(${this.$dayjs().format('YYYY-MM-DDTHH-mm')})`,
        showLabels: true,
        useKeysAsHeaders: true
      }

      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(mapped)
    },
    getAmount (type, colors) {
      const amount = colors.map((color) => {
        if (color[type]) {
          return color[type].reduce((total, acc) => total + acc.amount, 0)
        }
        return 0
      }).reduce((total, acc) => total + acc, 0)

      return amount || 0
    },
    reCalc () {
      this.formPrototypes = this.formPrototypes.map((prototype) => {
        const newPrototype = this.calculatePriceAndMargin(prototype)
        const { stockCost, stockValue } = this.caculateStockCostAndStockValue(newPrototype.order)

        return {
          ...newPrototype,
          order: {
            ...newPrototype.order,
            stockCost,
            stockValue
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.card {
  position: relative;
  min-height: 90vh;
}

.summary-tab {
  position: fixed;
  width: 77%;
  z-index: 998;
}

img.detail-box-image {
  cursor: pointer;
  max-height: 80px;
  transition: transform 0.1s;
}

.number-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 70%;
  border: 2px solid black;
  position: relative;
  margin: 0 4px;
  background-color: #fff;
}

.number-box .title {
  position: absolute;
  top: -17px;
  left: 5px;
  padding: 0 5px;
  background-color: #fff;
}

.number-box .extend {
  position: absolute;
  bottom: 0px;
  right: 2px;
  background-color: #fff;
}

.table {
  margin-top: 84px;
}
</style>
