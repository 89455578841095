var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-4 card"},[_c('img-popup-preview',{attrs:{"img-src":_vm.currentImgPreview},model:{value:(_vm.previewEnabled),callback:function ($$v) {_vm.previewEnabled=$$v},expression:"previewEnabled"}}),_c('div',{staticClass:"d-flex summary-tab"},[_c('v-card',{staticClass:"number-box",attrs:{"elevation":"2"}},[_c('h3',{staticClass:"title"},[_vm._v(" Avg. Margin ")]),_c('h2',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(_vm.averageMargin))+" ")])]),_c('v-card',{staticClass:"number-box",attrs:{"elevation":"2"}},[_c('h3',{staticClass:"title"},[_vm._v(" Stock Cost ")]),_c('h2',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(_vm.stockCost))+" ")])]),_c('v-card',{staticClass:"number-box",attrs:{"elevation":"2"}},[_c('h3',{staticClass:"title"},[_vm._v(" Stock Value ")]),_c('h2',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(_vm.stockValue))+" ")])]),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","height":"80","width":"80"},on:{"click":function($event){return _vm.exportCsv()}}},[_vm._v(" Export"),_c('br'),_vm._v("CSV ")]),_c('v-btn',{attrs:{"color":"success","height":"80","width":"80"},on:{"click":function($event){return _vm.saveOrders()}}},[_vm._v(" อัพเดต"),_c('br'),_vm._v("ใบงาน ")])],1),_c('v-data-table',{staticClass:"table",attrs:{"items-per-page":-1,"hide-default-footer":"","headers":_vm.headers,"items":_vm.formPrototypes},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"detail-box-image",attrs:{"src":item.image},on:{"click":function($event){return _vm.previewImg(item.image)}}})]}},{key:"item.manufacAmount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center my-4 text-center"},[_c('v-row',_vm._l((item.order.colors),function(c,i){return _c('v-col',{key:((c.color) + "-" + i),attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":c.color.toUpperCase(),"hide-details":"","dense":"","color":"secondary"},on:{"input":function($event){return _vm.reCalc()}},model:{value:(item.order.colors[i].totalQty),callback:function ($$v) {_vm.$set(item.order.colors[i], "totalQty", _vm._n($$v))},expression:"item.order.colors[i].totalQty"}})],1)}),1)],1)]}},{key:"item.order.sellPrice",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"hide-details":"","dense":"","color":"secondary"},on:{"input":function($event){return _vm.reCalc()}},model:{value:(item.order.sellPrice),callback:function ($$v) {_vm.$set(item.order, "sellPrice", _vm._n($$v))},expression:"item.order.sellPrice"}})]}},{key:"item.order.manufacCost",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"hide-details":"","dense":"","suffix":item.order.manufacCountry === 'china' ? '¥' : '฿',"color":"secondary"},on:{"input":function($event){return _vm.reCalc()}},model:{value:(item.order.manufacCost),callback:function ($$v) {_vm.$set(item.order, "manufacCost", _vm._n($$v))},expression:"item.order.manufacCost"}})]}},{key:"item.order.manufacCountry",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.order.manufacCountry === 'china' ? 'จีน' : 'ไทย')+" ")])]}},{key:"item.order.fabricsCost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.order.fabricsCost))+" ")]}},{key:"item.order.materialsCost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.order.materialsCost))+" ")]}},{key:"item.order.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.order.cost))+" ")]}},{key:"item.order.margin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.order.margin))+" ")]}},{key:"item.order.priceWithoutVat",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.order.priceWithoutVat))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }