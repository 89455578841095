var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-4 card"},[_c('img-popup-preview',{attrs:{"img-src":_vm.currentImgPreview},model:{value:(_vm.previewEnabled),callback:function ($$v) {_vm.previewEnabled=$$v},expression:"previewEnabled"}}),_c('div',{staticClass:"d-flex summary-tab"},[_c('v-card',{staticClass:"number-box",attrs:{"elevation":"2"}},[_c('h3',{staticClass:"title"},[_vm._v(" Avg. Margin ")]),_c('h2',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(_vm.averageMargin))+" ")])]),_c('v-card',{staticClass:"number-box",attrs:{"elevation":"2"}},[_c('h3',{staticClass:"title"},[_vm._v(" Stock Cost ")]),_c('h2',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(_vm.stockCost))+" ")])]),_c('v-card',{staticClass:"number-box",attrs:{"elevation":"2"}},[_c('h3',{staticClass:"title"},[_vm._v(" Stock Value ")]),_c('h2',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(_vm.stockValue))+" ")])]),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","height":"80","width":"80"},on:{"click":function($event){return _vm.exportCsv()}}},[_vm._v(" Export"),_c('br'),_vm._v("CSV ")]),_c('v-btn',{attrs:{"color":"success","height":"80","width":"80"},on:{"click":function($event){return _vm.saveOrders()}}},[_vm._v(" อัพเดต"),_c('br'),_vm._v("ใบงาน ")])],1),_c('v-data-table',{staticClass:"table",attrs:{"items-per-page":-1,"hide-default-footer":"","headers":_vm.headers,"items":_vm.calculatingOrders},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"detail-box-image",attrs:{"src":item.image},on:{"click":function($event){return _vm.previewImg(item.image)}}})]}},{key:"item.manufacAmount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.getManufacAmount(item.order.productSkus))+" ")])]}},{key:"item.order.manufacCountry",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"hide-details":"","dense":"","value":!!item.order.manufacCountry ? item.order.manufacCountry : 'thailand',"items":_vm.manufacCountries},on:{"input":function($event){return _vm.reCalc()}},model:{value:(item.order.manufacCountry),callback:function ($$v) {_vm.$set(item.order, "manufacCountry", $$v)},expression:"item.order.manufacCountry"}})]}},{key:"item.order.clothesCostPerMetr",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"hide-details":"","dense":""},on:{"input":function($event){return _vm.reCalc()}},model:{value:(item.order.clothesCostPerMetr),callback:function ($$v) {_vm.$set(item.order, "clothesCostPerMetr", _vm._n($$v))},expression:"item.order.clothesCostPerMetr"}})]}},{key:"item.order.sellPrice",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"hide-details":"","dense":""},on:{"input":function($event){return _vm.reCalc()}},model:{value:(item.order.sellPrice),callback:function ($$v) {_vm.$set(item.order, "sellPrice", _vm._n($$v))},expression:"item.order.sellPrice"}})]}},{key:"item.order.clothesLength",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"hide-details":"","dense":""},on:{"input":function($event){return _vm.reCalc()}},model:{value:(item.order.clothesLength),callback:function ($$v) {_vm.$set(item.order, "clothesLength", _vm._n($$v))},expression:"item.order.clothesLength"}})]}},{key:"item.order.costInCny",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"disabled":_vm.disableByCountry(item.order.manufacCountry, 'china'),"hide-details":"","dense":""},on:{"input":function($event){return _vm.reCalc()}},model:{value:(item.order.costInCny),callback:function ($$v) {_vm.$set(item.order, "costInCny", _vm._n($$v))},expression:"item.order.costInCny"}})]}},{key:"item.order.costInThb",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"disabled":_vm.disableByCountry(item.order.manufacCountry, 'thailand'),"hide-details":"","dense":""},on:{"input":function($event){return _vm.reCalc()}},model:{value:(item.order.costInThb),callback:function ($$v) {_vm.$set(item.order, "costInThb", _vm._n($$v))},expression:"item.order.costInThb"}})]}},{key:"item.order.materialsCost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.order.materialsCost))+" ")]}},{key:"item.order.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.order.cost))+" ")]}},{key:"item.order.margin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.order.margin))+" ")]}},{key:"item.order.priceWithoutVat",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.order.priceWithoutVat))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }